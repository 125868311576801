import {NgModule, Optional, SkipSelf} from '@angular/core';
import {ToastComponent} from './toast.component';
import {ToastService} from "./toast.service";
import {CommonModule} from "@angular/common";


@NgModule({
  imports: [CommonModule],
  declarations: [ToastComponent],
  entryComponents: [ToastComponent],
  providers: [ToastService],
})
export class ToastModule {

  constructor(@Optional() @SkipSelf() parentModule: ToastModule) {
    if (parentModule) {
      throw new Error('ToastModule is already loaded. Import it in the AppModule only!');
    }

  }
}
