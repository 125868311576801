<div class="first-header" fxLayout="row" fxLayoutAlign="start">
  <img fxHide.xs="true"
       src="https://lh6.googleusercontent.com/8LBd2_HR1-SE90BnHwD8X0lM8OtXnz7NLTRuPRQL_vOzryRHt7qGyuML2ogq-ZgqiiDBbdcMiyLxFtOW_d8gXqHcOrkpJgVO7eNAqV4rJP7tyBUodB_h5tnt5aqD=w954"
       alt="">
  <div class="total" fxFlex="85" fxLayoutAlign="end center">
    <a routerLink="/order/list" routerLinkActive="active">Listă Comenzi</a>
    <a routerLink="/products" routerLinkActive="active">Listă Produse</a>
    <a (click)="logout()">Logout</a>
  </div>
</div>


<div class="main-container">
  <div class="products-container" fxLayout="column" fxLayoutGap="1.5%" fxLayoutAlign="start center">
    <div class="add" fxFlex="5" fxLayoutAlign="end center">
      <i class="material-icons" (click)="addProduct('add')">add_circle_outline</i>
    </div>
    <div class="product-line" *ngFor="let product of products | sort: 'number' " fxFlex="12" fxLayout="row"
         fxLayoutGap="3%">
      <div class="photo" fxFlex="13" fxLayoutAlign="center center" fxHide.xs>
        <img src="{{product.photo}}">

      </div>

      <div fxFlex fxLayout="column" fxFlex.xs="35">
        <p>Nume Produs</p>
        <span>{{product.name}}</span>
      </div>

      <div fxFlex="7" fxLayout="column">
        <p>Unitate</p>
        <span>{{product.unit}}</span>
      </div>

      <div fxFlex="7" fxLayout="column">
        <p>Categorie</p>
        <span>{{product.category}}</span>
      </div>

      <div fxFlex="7" fxLayout="column">
        <p>Favorit</p>
        <span>{{product.favourite}}</span>
      </div>

      <div fxFlex="7" fxLayout="column">
        <p>Preț</p>
        <span>{{product.price}} Lei</span>
      </div>

      <div fxFlex="7" fxLayout="column" fxFlex.xs="12">
        <p>Poziție</p>
        <span>{{product.number}}</span>
      </div>


      <div class="buttons" fxLayout="row" fxFlex="10" fxFlex.xs fxLayoutAlign="center center" fxLayoutGap="10%"
           fxLayout.xs="column">

        <i class="material-icons" fxFlex="40" (click)="deleteProduct(product)">delete</i>
        <i class="material-icons" fxFlex="40" (click)="editProduct(product, 'edit')">edit</i>
      </div>


    </div>
  </div>
  <i class="material-icons" (click)="addMotd()"
     style="position: absolute; right: 20px; bottom: 30px;">add_circle_outline</i>

</div>

