import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
@Injectable()
export class AuthGuardService implements CanActivate {
  user;
  constructor( public router: Router) {}
  canActivate(): boolean {
   this.user = JSON.parse(localStorage.getItem("loggedUser"));

     if (!this.user) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
