import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {DataService} from "../data.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-motd',
  templateUrl: './add-motd.component.html',
  styleUrls: ['./add-motd.component.scss']
})
export class AddMotdComponent implements OnInit {

  motdForm: FormGroup;
  motdFormErrors: any;
  _unsubscribeAll;
  product;

  constructor(private _formBuilder: FormBuilder,
              private dataService: DataService,
              public dialogRef: MatDialogRef<AddMotdComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.motdForm = this._formBuilder.group({
      id: [''],
      title: [''],
      content1: [''],
      content2: [''],
      photo: [''],
    });
    this.motdForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.onClientFormValueChanged();
      });
  }

  onClientFormValueChanged(): void {
    for (const field in this.motdFormErrors) {
      if (!this.motdFormErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.motdFormErrors[field] = {};

      // Get the control
      const control = this.motdForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.motdFormErrors[field] = control.errors;
      }
    }
  }

  ////Mark all form fields as touched///
  private markFormGroupTouched(productForm: FormGroup) {
    (<any>Object).values(productForm.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  addMotd(form) {
    if (form.status == "VALID") {
      this.dataService.addMotd(form.value).then(motd => {
        this.data.add(motd);
        this.dialogRef.close();
      }).catch(error =>
        console.log(error))
    } else {
      this.markFormGroupTouched(form);
    }
  }
}
