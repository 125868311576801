<div class="order-container" fxLayoutAlign="center center" fxLayout="column">

  <div class="banner full">
    <div class="img" src="assets/images/banner.jpg" alt=""></div>
    <div class="text">
      <p>MERERO ESTE SURSA TA DE SĂNĂTATE!</p>
      <hr>
    </div>
  </div>
  <div class="form-container" fxFlex="100">

    <form class="text-center" fxlayoutAling="row wrapp"
          fxLayoutAlign="center center">
      <div class="hero-text">
        <p>SUC DE MERE NATURAL 100%</p>
        <hr>
        <h4 style="font-size: 20px;color: black">Producători de mere și sucuri naturale din mere!</h4>
        <h4 style="font-size: 20px; padding: 0 5%">Livrăm personal la domiciliu în fiecare saptamană în București și
          Ilfov, suc natural
          100% produs din mere de Voinești, obținut prin presare la rece și pasteurizare, fără adaosuri de conservanți
          și îndulcitori.</h4>
        <h4 style="font-size: 20px">Transportul este gratuit la comenzile ce depășesc 60 Lei.</h4>
        <h2 style="color:red" *ngIf="motd">{{motd ? motd.content1 : ''}}</h2>
        <h4 style="font-size: 20px" *ngIf="motd">{{motd ? motd.content2 : ''}}</h4>

      </div>

      <mat-tab-group (selectedTabChange)="selectCategory($event.tab.textLabel)">
        <mat-tab label="">
          <ng-template mat-tab-label>
            <span>Toate produsele</span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Sucuri3l">
          <ng-template mat-tab-label>
            <span>Sucuri 3L</span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Sucuri1l">
          <ng-template mat-tab-label>
            <span>Sucuri 1L</span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Sucuri033">
          <ng-template mat-tab-label>
            <span>Sucuri 0.33L</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Fructe">
          <ng-template mat-tab-label>
            <span>Fructe</span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Dulceturi">
          <ng-template mat-tab-label>
            <span>Dulcețuri</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Alte">
          <ng-template mat-tab-label>
            <span>Alte Bunătăți</span>
          </ng-template>
        </mat-tab>

      </mat-tab-group>


      <app-product-card (onSelectedValue)="selectValue($event)" *ngFor="let product of  productList "
                        fxFlex.gt-sm="0 1 45%"
                        [product]="product"></app-product-card>

      <div class="default-text" *ngIf="productList?.length == 0">
        <p>Momentan nu avem produse din categoria selectata.</p>
      </div>
    </form>

    <div class="button" fxLayout="column" fxFlexAlign="left left"
         fxLayoutAlign="space-between center">
      <button (click)="goToOrder()"
              fxLayoutAlign="center center">Trimite Comanda <i style="padding-left: 10px"
                                                               class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>

</div>
