<div class="first-header" fxLayout="row" fxLayoutAlign="start">
  <img fxHide.xs="true"
       src="https://lh6.googleusercontent.com/8LBd2_HR1-SE90BnHwD8X0lM8OtXnz7NLTRuPRQL_vOzryRHt7qGyuML2ogq-ZgqiiDBbdcMiyLxFtOW_d8gXqHcOrkpJgVO7eNAqV4rJP7tyBUodB_h5tnt5aqD=w954"
       alt="">
  <div class="total" fxFlex="85" fxLayoutAlign="end center">
    <a routerLink="/order/list" routerLinkActive="active">Listă Comenzi</a>
    <a routerLink="/products" routerLinkActive="active">Listă Produse</a>
    <a (click)="logout()">Logout</a>
  </div>
</div>

<div class="main-container" fxLayoutAlign="start center" fxLayout="column">

  <cdk-virtual-scroll-viewport style="height: 100%;width: 100%" itemSize="10">
    <div *cdkVirtualFor="let order of orders index as indexElement" >
      <div class="card" fxLayout="row" fxLayoutAlign="start center"
           fxFlex="50" fxFlex.md="65" fxFlex.sm="80" fxFlex.xs="100">
        <div class="billing" fxLayout="column" fxFlex="30" fxlayout="end end">

          <div class="column" fxLayoutAlign="start start" fxFlex="15" fxLayout="column">
            <h4 fxFlex="35">Data</h4>
            <p4 fxFlex="65">{{order.date | date: 'dd-MM-yyy'}}</p4>
          </div>

          <div class="column" fxLayoutAlign="start start" fxFlex="15" fxLayout="column">
            <h4 fxFlex="35">Nume</h4>
            <p4 fxFlex="65">{{order.name}}</p4>
          </div>


          <div class="column" fxLayoutAlign="start start" fxFlex="15" fxLayout="column">
            <h4 fxFlex="35">Telefon</h4>
            <p4 fxFlex="65">{{order.phoneNumber}}</p4>
          </div>

          <div class="column" fxLayoutAlign="start start" fxFlex="45" fxLayout="column">
            <h4 fxFlex="15">Adresă</h4>
            <p4 fxFlex="80">{{order.address}}</p4>
          </div>

<!--          <div class="column" fxLayoutAlign="start start " fxFlex="25" fxLayout="column">-->
<!--            <h4 fxFlex="15">Dată Livrare</h4>-->
<!--            <p4 fxFlex="80">{{order.deliveryDate}}</p4>-->
<!--          </div>-->


          <div class="column" fxLayoutAlign="start start " fxFlex="20" fxLayout="column">
            <h4 fxFlex="35">Metodă de plată </h4>
            <p4 fxFlex="65">{{order.promoCode}}</p4>
          </div>  <div class="column" fxLayoutAlign="start start " fxFlex="45" fxLayout="column">
            <h4 fxFlex="25">Mențiuni</h4>
            <p4 fxFlex="75">{{order.remarks}}</p4>
          </div>

        </div>

        <div class="verticalLine"></div>

        <div class="order" fxFlex="70" fxLayout="column">
          <div class="header" fxFlex="12" fxLayout="row">
            <p fxFlex="70">Produs</p>
            <p fxFlex="30">Cantitate</p>
          </div>
          <div class="order-container" fxFlex="76" fxLayout="column">
            <ng-container *ngFor="let product of order.productList | sort:'number'">
              <div class="row" fxFlex="10" fxLayout="row" *ngIf="product.quantity!=0">
                <p fxFlex="70">{{product.name}}</p>
                <p fxFlex="30">{{product.quantity}}</p>
              </div>
            </ng-container>

          </div>
          <div class="total" fxFlex="12" fxLayout="row">
            <div fxFlex="50" fxLayoutAlign="start center">
              <span style="font-weight: bold">Total {{order.totalPrice}} Lei</span>
            </div>

            <div class="button" fxLayout="column" fxFlexAlign="end  center" fxFlex="50">
              <button (click)="deleteOrder(order)"
                      fxLayoutAlign="center center">Șterge
              </button>
            </div>

          </div>

        </div>


      </div>

    </div>
  </cdk-virtual-scroll-viewport>

</div>
