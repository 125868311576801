import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToastComponent {

  public opened = false;

  public message = '';

  private timeout;

  constructor() {
  }

  showToast(message) {
    this.message = message;
    this.opened = true;
    this.timeout = setTimeout(() => {
      this.opened = false;
    }, 3500);
  }

  close() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.opened = false;
  }
}
