<div class="mainContainer" fxLayout="column">
  <div class="card-header" fxFlex="14" fxLayoutAlign="center center">
    <h1 *ngIf="data.param=='edit'">Editează produsul</h1>    <h1 *ngIf="data.param=='add'">Adaugă un produs</h1>

  </div>
  <div class="container" fxLayout="column" fxFlex fxLayoutAlign="center center">
    <form class="text-center" name="registerForm" [formGroup]="productForm">

      <mat-form-field appearance="outline">
        <mat-label>Nume Produs -ILIE-</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-error
          *ngIf="productForm.get('name').errors && productForm.get('name').errors.required">
          Numele produsului este obligatoriu.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Categorie produs</mat-label>
        <mat-select formControlName="category">
          <mat-option value="Sucuri3l">Sucuri 3l</mat-option>
          <mat-option value="Sucuri1l">Sucuri 1l</mat-option>
          <mat-option value="Sucuri033">Sucuri 0.33l</mat-option>
          <mat-option value="Dulceturi">Dulceturi</mat-option>
          <mat-option value="Fructe">Fructe</mat-option>
          <mat-option value="Alte">Alte Bunatati</mat-option>
        </mat-select>
        <mat-error
          *ngIf="productForm.get('category').errors && productForm.get('category').errors.required">
          Categoria produsului este obligatorie.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Categoria -cele mai vandute</mat-label>
        <mat-select formControlName="favourite">
          <mat-option value="Da">Da</mat-option>
          <mat-option value="Nu">Nu</mat-option>
        </mat-select>
        <mat-error
          *ngIf="productForm.get('favourite').errors && productForm.get('favourite').errors.required">
         Alege daca doresti ca produsul sa apara sau nu in aceasta categorie.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Descriere</mat-label>
        <input matInput type="text" formControlName="description">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Continut</mat-label>
        <input matInput type="text" formControlName="contains">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Unitate de masură</mat-label>
        <input matInput type="text" formControlName="unit">
        <mat-error
          *ngIf="productForm.get('unit').errors && productForm.get('unit').errors.required">
          Unitatea de masură a produsului este obligatorie.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Preț</mat-label>
        <input matInput type="number" formControlName="price">
        <mat-error
          *ngIf="productForm.get('price').errors && productForm.get('price').errors.required">
          Prețul produsului este obligatoriu.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Poziție</mat-label>
        <input matInput type="number" formControlName="number">
        <mat-error
          *ngIf="productForm.get('number').errors && productForm.get('number').errors.required">
          Poziția produsului în lista este obligatorie.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Poza</mat-label>
        <input matInput type="text" formControlName="photo">
        <mat-error
          *ngIf="productForm.get('photo').errors && productForm.get('photo').errors.required">
          Link-ul pozei este obligatoriu.
        </mat-error>
      </mat-form-field>
    </form>

    <button mat-raised-button aria-label="Save" *ngIf="data.param=='edit'"
            (click)="editProduct(productForm)" class="submit"
            fxLayoutAlign="center center">
      <span>Salvează</span>
    </button>
    <button mat-raised-button aria-label="Save" *ngIf="data.param=='add'"
            (click)="addProduct(productForm)" class="submit"
            fxLayoutAlign="center center">
      <span>Adaugă</span>
    </button>

  </div>


</div>
