<div class="mainContainer" fxLayout="column">
  <div class="card-header" fxFlex="14" fxLayoutAlign="center center">
    <h1 >Adauga/Editeaza mesajul</h1>

  </div>
  <div class="container" fxLayout="column" fxFlex fxLayoutAlign="center center">
    <form class="text-center" name="registerForm" [formGroup]="motdForm">
      <mat-form-field appearance="outline">
        <mat-label>Titlu</mat-label>
        <input matInput type="text" formControlName="content1">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Continut</mat-label>
        <input matInput type="text" formControlName="content2">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Url poza</mat-label>
        <input matInput type="text" formControlName="photo">
      </mat-form-field>
    </form>


    <button mat-raised-button aria-label="Save"
            (click)="addMotd(motdForm)" class="submit"
            fxLayoutAlign="center center">
      <span>Salveaza</span>
    </button>

  </div>


</div>
