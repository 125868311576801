import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {OrderPageComponent} from './order-page/order-page.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {OrderListComponent} from './order-list/order-list.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {OrderFinalizeComponent} from './order-finalize/order-finalize.component';
import {ProductCardComponent} from './product-card/product-card.component';
import {ToastModule} from './toast/toast.module';
import {ProductsComponent} from './products/products.component';
import {CheckComponent} from './check/check.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AddProductComponent} from './add-product/add-product.component';
import {LoginComponent} from './login/login.component';
import {OrderPipe} from "./orderPipe";
import {AuthGuardService} from "./auth.service";
import {FacebookModule} from "ngx-facebook";
import {CookieService} from "ngx-cookie-service";
import {AboutComponent} from './about/about.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {OrderModule} from "ngx-order-pipe";
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatTabsModule} from "@angular/material/tabs";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import { AddMotdComponent } from './add-motd/add-motd.component';
import {MatRadioButton, MatRadioModule} from "@angular/material/radio";

@NgModule({
  declarations: [
    AppComponent,
    OrderPageComponent,
    OrderDetailsComponent,
    OrderListComponent,
    OrderFinalizeComponent,
    ProductCardComponent,
    ProductsComponent,
    CheckComponent,
    AddProductComponent,
    LoginComponent,
    OrderPipe,
    AboutComponent,
    AddMotdComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    MatDialogModule,
    MatSidenavModule,
    FacebookModule.forRoot(),
    OrderModule,
    ScrollingModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
  ],
  entryComponents: [CheckComponent,
    AddProductComponent
  ],
  providers: [AuthGuardService, CookieService,
    {provide: MAT_DATE_LOCALE, useValue: 'ro-RO'},],
  bootstrap: [AppComponent]
})
export class AppModule {

}
