import {Component, ElementRef, OnInit, Output, QueryList, ViewChild,} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataService} from '../data.service';
import {Router} from '@angular/router';
import {ProductCardComponent} from '../product-card/product-card.component';
import {ToastService} from '../toast/toast.service';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss']
})
export class OrderPageComponent implements OnInit {
  @ViewChild(ProductCardComponent) child;


  order = {};
  quantityProducts = [];
  totalClass = 'Invalid';
  orderPrice = [];
  message;
  products;
  productList;
  test = [];
  total;
  allProducts;
  motd;

  constructor(private _formBuilder: FormBuilder,
              private dataService: DataService,
              private router: Router,
              private toastService: ToastService,) {
  }


  ngOnInit() {

    this.dataService.gotoTop();
    this.dataService.getAllProducts().then(products => {
      this.allProducts = products;
      this.productList = products;
      this.selectCategory('')
    })
    this.dataService.getMotd().then(motd => {
      this.motd = motd;
    })

  }

  selectCategory(param: string) {
    this.productList = [];
    if (param === '') {
      this.productList = this.allProducts;
    }
    if (param === 'Favourite') {
      this.productList = this.allProducts.filter(product =>
        product.favourite == "Da"
      )
    }
    // get data from localStorage

    this.orderPrice = JSON.parse(localStorage.getItem("orderPrice"));
    if (this.orderPrice == null) {
      this.orderPrice = [];
    }
    this.total = JSON.parse(localStorage.getItem("total"));
    if (this.quantityProducts === null) {
      this.quantityProducts = [];
    }
    if (JSON.parse(localStorage.getItem("quantityProducts")) == null) {
      this.quantityProducts = [];
    } else if (JSON.parse(localStorage.getItem("quantityProducts")) != null) {
      this.quantityProducts = JSON.parse(localStorage.getItem("quantityProducts"));

    }

    if (this.allProducts && param != 'Favourite' && param != "") {
      this.products = this.allProducts.filter(product => product.category == param)
      for (let j = 0; j < this.products.length; j++) {
        this.productList.push({
          id: this.products[j].id,
          number: this.products[j].number,
          name: this.products[j].name,
          category: this.products[j].category,
          favourite: this.products[j].favourite,
          photo: this.products[j].photo,
          price: this.products[j].price,
          unit: this.products[j].unit,
          description: this.products[j].description,
          contains: this.products[j].contains,
        })

        if (this.quantityProducts) {
          for (let k = 0; k < this.quantityProducts.length; k++) {
            if (this.products[j].id == this.quantityProducts[k].productId) {
              this.productList.splice(this.productList.indexOf(this.products[j]), 1);
              this.productList.push({
                id: this.products[j].id,
                number: this.products[j].number,
                name: this.products[j].name,
                category: this.products[j].category,
                favourite: this.products[j].favourite,
                photo: this.products[j].photo,
                price: this.products[j].price,
                unit: this.products[j].unit,
                quantity: this.quantityProducts[k].quantity,
                description: this.products[j].description,
                contains: this.products[j].contains,
              })
            }
          }
        }
      }
    }

    this.productList.sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0)
    // to do   --change filter
  }

  goToOrder() {
    this.router.navigateByUrl('order/details')
  }

  sendOrder(form) {
    if (form.status == "VALID") {
      this.order = {
        quantityProducts: this.quantityProducts,
        name: form.value.name,
        address: form.value.address,
        phoneNumber: form.value.phoneNumber,
        remarks: form.value.remarks,
      }
      if (this.total >= 60) {
        this.router.navigateByUrl('order/details')

      } else {
        this.toastService.show('Comanda minimă este de 60 Lei');
      }
    } else {
      this.toastService.show('Completează campurile obligatorii marcate cu * ')

    }
  }


  selectValue($event) {

    this.total = 0;
    this.message = $event;
    let k = false;
    let productTuple: [number, number];
    productTuple = [$event.productId, $event.productPrice * $event.quantity]
    for (let j = 0; j < this.orderPrice.length; j++) {
      if (this.orderPrice[j][0] == $event.productId) {
        this.orderPrice[j][1] = $event.productPrice * $event.quantity
        k = true;
      }
    }
    if (!k) {
      this.orderPrice.push(productTuple)
    }
    localStorage.setItem("orderPrice", JSON.stringify(this.orderPrice));
    for (let j = 0; j < this.orderPrice.length; j++) {
      this.total += this.orderPrice[j][1]
    }

    // sending order price to service
    this.dataService.sendOrderTotal(this.total)
    localStorage.setItem("total", JSON.stringify(this.total));
    //remove modified products
    this.quantityProducts = this.quantityProducts.filter(product => product.productId != $event.productId)
    this.quantityProducts.push($event)
    localStorage.setItem("quantityProducts", JSON.stringify(this.quantityProducts));
  }


}

