<div class="main-container" fxLayout="column">

  <div class="banner-first full">
    <div class="img" src="assets/images/banner.jpg" alt=""></div>
    <div class="text">
      <p>MERERO ESTE SURSA TA DE SĂNĂTATE!</p>
      <hr>
    </div>
  </div>
  <div class="cards-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="button" fxLayout="column" fxFlexAlign="left left" (click)="moveToOrder()"
         fxLayoutAlign="space-between center" style="margin: 4% 0 0 0">
      <button
        fxLayoutAlign="center center">Pentru a plasa o comandă apasă aici <i style="padding-left: 10px"
                                                                             class="fas fa-chevron-right"></i>
      </button>
    </div>
    <div class="card" fxLayout="column" fxLayoutGap="5%" fxLayout.gt-sm="row wrapp" fxLayoutGap.gt-sm="5%"
         fxLayoutAlign="center center">
      <div class="text-card" fxFlex.gt-xs="0 1 42%" fxLayout="column" fxFlex="40">
        <div class="text-header">
          <p>TRADIȚIE DIN 1950</p>
          <hr>
          <p> VOINEȘTI</p>
          <h2>Despre noi</h2>
        </div>
        <p>Vă oferim produse naturale, de calitate, rezultate în mare parte din producția livezii noastre dar si din
          livezile producătorilor locali, situate pe frumoasele dealuri din Voinești unde livezile de meri se întind cât
          vezi cu ochii. Merele de aici se coc sub soarele domol al verii târzii ce le oferă o dulceață acidă care
          îmbogățește gustul sucului proaspăt stors.</p>
      </div>
      <img src="assets/images/about.jpg" alt="" fxFlex.gt-xs="0 1 40%" fxFlex="40">
    </div>

    <div class="card" fxLayout="column" fxLayoutGap="5%" fxLayout.gt-sm="row wrapp" fxLayoutGap.gt-sm="5%"
         fxLayoutAlign="center center">
      <img src="assets/images/apus.jpg" alt="" fxFlex.gt-xs="0 1 40%" fxFlex="40">
      <div class="text-card" fxFlex="40" fxFlex.gt-xs="0 1 42%" fxLayout="column">
        <div class="text-header">
          <p>TRADIȚIE DIN 1950</p>
          <hr>
          <p> VOINEȘTI</p>
          <h2>De când a început totul?</h2>
        </div>
        <p>Povestea merelor de Voinești datează încă de pe vremea bunicilor, pomicultura fiind activitatea de
          subzistență in acest sat iar din anul 1950 după un studiu de mediu a fost înființată Stațiunea de Cercetare
          și producție pomicolă in urma căreia Merele de Voinești au devenit produse de origine geografică.</p>
      </div>
    </div>

    <div class="banner-second full">
      <div class="img" alt=""></div>
      <div class="text">
        <h2>Cum am ajuns să ducem mai departe tradiția?</h2>
        <p>Ne-am simțit norocoși să știm că putem valorifica această "moștenire" a familiei așa că am început să punem
          în valoare ce avem, adaptat vremurilor din prezent și nevoilor consumatorilor. În 2010 am înființat o nouă
          livadă mai productivă, înlocuind pomii bătrani cu puieți tineri. Ulterior am achiziționat o instalație de
          procesare legume fructe. Inițial am oferit servicii de procesare a merelor pe plan local, ulterior oamenii au
          aflat de noi și au început să străbată sute de kilometri pentru a-și putea procesa fructele din propriile
          livezi. Este o alternativă bună pentru pomicultorii ce pierdeau pe fiecare sezon tone de mere refuzate de
          clienții din piață. Ce poți face cu un măr imperfect? Chit ca este o simplă cicatrice rezultată în urma unei
          ploi cu grindină? De când supermarketurile își etalează marfa importată mai ceva ca la un concurs de frumusețe
          și clienții noștri autohtoni au devenit mai pretențioși în ceea ce privește perfecțiunea fructului.</p>
        <hr>
      </div>
    </div>

    <!--    <div class="card" fxLayout="column" fxLayoutGap="5%" fxLayout.gt-sm="row wrapp" fxLayoutGap.gt-sm="5%"-->
    <!--         fxLayoutAlign="center center">-->
    <!--      <div class="text-card" fxFlex.gt-xs="0 1 42%" fxLayout="column" fxFlex="40">-->
    <!--        <div class="text-header">-->
    <!--          <p>TRADIȚIE DIN 1950</p>-->
    <!--          <hr>-->
    <!--          <p> VOINEȘTI</p>-->
    <!--          <h2>De ce am ales MereRo?</h2>-->
    <!--        </div>-->
    <!--        <p>De la Mere Românești! Când spui MereRo nu te referi la o simplă denumire a unui produs ci la o adevărată-->
    <!--          relație a omului cu natura.</p>-->
    <!--      </div>-->
    <!--      <img src="assets/images/apus.jpg" alt="" fxFlex.gt-xs="0 1 40%" fxFlex="40">-->
    <!--    </div>-->

    <div class="card" fxLayout="column" fxLayoutGap="5%" fxLayout.gt-sm="row wrapp" fxLayoutGap.gt-sm="5%"
         fxLayoutAlign="center center">
      <img src="assets/images/sticle.jpg" alt="" fxFlex.gt-xs="0 1 40%" fxFlex="40">
      <div class="text-card" fxFlex.gt-xs="0 1 42%" fxLayout="column" fxFlex="40">
        <div class="text-header">
          <p>TRADIȚIE DIN 1950</p>
          <hr>
          <p> VOINEȘTI</p>
          <h2>De când comercializăm produsele?</h2>
        </div>
        <p>Am ales MereRo de la Mere Românești. Trei ani mai târziu am decis că e momentul să împărtășim produsele
          noastre cu cei ce apreciază munca
          producătorului de la țară, cei ce iubesc roadele și bogăția pământului românesc. Am fost prezenți în diferite
          târguri cu specific tradițional unde interacțiunea cu oamenii era în mod direct. Așa am descoperit că se caută
          din ce în ce mai mult gustul merelor românești, gustul de odinioară. Auzeam destul de des după ce gustau
          dintr-un măr expresia : "- Ahh! Astea sunt mere de la mama lor, de la Voinești!". Văzând satisfacția lor ne-am
          ambiționat să fim în continuare aproape de clienți, să le ascultăm poveștile, să le știm nevoile și să le
          putem oferi ceea ce își doresc, diversificând gama de produse, oferindu-le de la an la an ceva nou.</p>
      </div>
    </div>

    <div class="card" fxLayout="column" fxLayoutGap="5%" fxLayout.gt-sm="row wrapp" fxLayoutGap.gt-sm="5%"
         fxLayoutAlign="center center">
      <div class="text-card" fxFlex.gt-xs="0 1 42%" fxLayout="column" fxFlex="40">
        <div class="text-header">
          <p>TRADIȚIE DIN 1950</p>
          <hr>
          <p> VOINEȘTI</p>
          <h2>De ce am trecut la comerțul online?</h2>
        </div>
        <p>Pentru că vremurile de astăzi defavorizează timpul și se pare că este din ce în ce mai scurt iar mulți
          preferă să îl folosească alături de familie. Livrările au ajuns o necesitate și a trebuit să ne adaptăm. Am
          păstrat relația directă cu clienții, livrând personal și nu am acceptat ca un ecran să ne pună o barieră. Asta
          pentru că ne preocupă să știm că toți oamenii sunt mulțumiți și vrem sa le oferim încredere!</p>
      </div>
      <img src="assets/images/livrare.jpg" alt="" fxFlex.gt-xs="0 1 40%" fxFlex="40">
    </div>

    <div class="stamps" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column" >

      <div class="stamp" fxFlex="20" fxLayoutAlign="center center" fxLayout="column">
        <h5>Sucuri 100% naturale</h5>
        <i class="fab fa-envira"></i>
      </div>
      <div class="stamp" fxFlex="20" fxLayoutAlign="center center" fxLayout="column">
        <h5>Presate la rece</h5>
        <i class="fas fa-temperature-low"></i>
      </div>
      <div class="stamp" fxFlex="20" fxLayoutAlign="center center" fxLayout="column">
        <h5>Doar din fructe</h5>
        <i class="fas fa-apple-alt"></i>

      </div>
      <div class="stamp" fxFlex="20" fxLayoutAlign="center center" fxLayout="column">
        <h5>De la producător</h5>
        <i class="fas fa-tractor"></i>
      </div>
      <div class="stamp" fxFlex="20" fxLayoutAlign="center center" fxLayout="column">
        <h5>Fabricate în România</h5>
        <i class="fas fa-map-marker-alt"></i>
      </div>
<!--      <div class="stamp" fxFlex="20" fxLayoutAlign="center center" fxLayout="column">-->
<!--        <h5>Transport gratuit</h5>-->
<!--        <i class="fas fa-truck-moving"></i>-->
<!--      </div>-->

    </div>

    <div class="button" fxLayout="column" fxFlexAlign="left left" (click)="moveToOrder()"
         fxLayoutAlign="space-between center">
      <button
        fxLayoutAlign="center center">Comandă aici <i style="padding-left: 10px" class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>


</div>
<!---->
