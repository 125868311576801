<div class="mainContainer" fxLayout="column">
  <div class="card-header" fxFlex="35" fxLayoutAlign="center center">
    <h1>Confirmă</h1>
  </div>

  <h2 fxFlex="10">Ești sigur că vrei sa ștergi comanda ?</h2>

  <div class="buttons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50" fxFlex>
    <button mat-button color="primary" type="submit" class="submit-button" aria-label="No"
            style="margin-right: 120px;background-color:#a93744;color: white" (click)="reject()">Nu
    </button>
    <button mat-raised-button color="primary" type="submit" class="submit-button" aria-label="Yes" (click)="accept()"
            style="background-color:#eaeaea;color: #889aa6;border-radius: 3px;">Da
    </button>
  </div>

</div>
