import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {DataService} from "../data.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {ToastService} from "../toast/toast.service";
import {CheckComponent} from "../check/check.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 1 && day !== 5 && day !== 3;
  }

  order;
  products;
  total;
  address;
  city;
  phoneNumber;
  phoneNumber2;
  remarks;
  name;
  deliveryDate;
  promoCode = '';
  _unsubscribeAll;
  orderForm: FormGroup;
  orderFormErrors;
  state$: Observable<object>;
  minDate;
  maxDate;



  constructor(private router: Router,
              private dialog: MatDialog,
              private toastService: ToastService,
              private _formBuilder: FormBuilder,
              private dataService: DataService,
              public activatedRoute: ActivatedRoute) {
    this.products = JSON.parse(localStorage.getItem("quantityProducts")) != null ? JSON.parse(localStorage.getItem("quantityProducts")).filter(product => product.quantity != 0) : [];
    this.name = localStorage.getItem("name")
    this.phoneNumber = localStorage.getItem("phoneNumber")
    this.phoneNumber2 = localStorage.getItem("phoneNumber2")
    this.address = localStorage.getItem("address")
    this.city = localStorage.getItem("city")
    this.remarks = localStorage.getItem("remarks")
    this.total = localStorage.getItem("total")
    // this.deliveryDay = localStorage.getItem("deliveryDay")

    this._unsubscribeAll = new Subject();

  }


  ngOnInit(): void {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 35);
    this.dataService.gotoTop();
    // build form
    this.orderForm = this._formBuilder.group({
      name: [this.name ? this.name : '', Validators.required],
      phoneNumber: [this.phoneNumber ? this.phoneNumber : '', Validators.required],
      phoneNumber2: [this.phoneNumber2 ? this.phoneNumber2 : ''],
      address: [this.address ? this.address : '', Validators.required],
      city: [this.city ? this.city : '', Validators.required],
      remarks: [this.remarks ? this.remarks : ''],
      // deliveryDate: [this.deliveryDate ? this.deliveryDate : '', Validators.required],
      promoCode: [this.promoCode ? this.promoCode : '',Validators.required],

    });
    this.orderForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        localStorage.setItem("name", this.orderForm.value.name)
        localStorage.setItem("phoneNumber", this.orderForm.value.phoneNumber)
        localStorage.setItem("phoneNumber2", this.orderForm.value.phoneNumber2)
        localStorage.setItem("address", this.orderForm.value.address)
        localStorage.setItem("city", this.orderForm.value.city)
        localStorage.setItem("remarks", this.orderForm.value.remarks)
        localStorage.setItem("promoCode", this.orderForm.value.promoCode)
        // localStorage.setItem("deliveryDate", this.orderForm.value.deliveryDay)
        this.onOrderFormValueChanged();
      });

  }

  onOrderFormValueChanged(): void {
    for (const field in this.orderFormErrors) {
      if (!this.orderFormErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.orderFormErrors[field] = {};

      // Get the control
      const control = this.orderForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.orderFormErrors[field] = control.errors;
      }
    }
  }


  finalizeOrder(form) {
    // to do   mutat in functie
    // this.deliveryDate = form.value.deliveryDate.toLocaleDateString();
    // console.log(this.deliveryDate)
    if (form.status == "VALID") {

      this.order = {
        quantityProducts: this.products,
        name: form.value.name,
        address: form.value.city +", " + form.value.address,
        phoneNumber: form.value.phoneNumber + " " + " " +form.value.phoneNumber2,
        remarks: form.value.remarks,
        // deliveryDate: this.deliveryDate,
        promoCode: form.value.promoCode,
        totalPrice: this.total,

      }
      if (this.total >= 60) {
        this.dataService.addOrder(this.order).then(order => {
          this.router.navigateByUrl('order/finalize')
        })
        this.dataService.sendOrderTotal(0);
        localStorage.setItem("quantityProducts", JSON.stringify([]));
        localStorage.setItem("orderPrice", JSON.stringify([]));
        localStorage.setItem("total", JSON.stringify(0));
        localStorage.setItem("phoneNumber", "")
        localStorage.setItem("phoneNumber2", "")
        localStorage.setItem("address", "")
        localStorage.setItem("city", "")
        localStorage.setItem("remarks", "")
        localStorage.setItem("name", "")
        localStorage.setItem("promoCode", "")
        // localStorage.setItem("deliveryDate", "")
        this.router.navigateByUrl('order/finalize')
        this.toastService.show('Comanda a fost plasată');
      } else {
        this.toastService.show('Comanda minimă este de 60 Lei');
      }
    } else {
      this.toastService.show('Completează campurile obligatorii marcate cu * ')
      this.gotoTop();
    }
  }

  // scroll to invalid form fields
  gotoTop() {
    window.scroll({
      top: 700,
      left: 0,
      behavior: 'smooth'
    });
  }

  public back() {
    this.router.navigateByUrl('/order');
  }

  public emptyShoppingCart() {

    const dialogRef = this.dialog.open(CheckComponent, {panelClass: 'check-dialog-container'});
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.dataService.sendOrderTotal(0);
        this.products = [];
        this.total = 0;
        localStorage.setItem("quantityProducts", JSON.stringify([]));
        localStorage.setItem("orderPrice", JSON.stringify([]));
        localStorage.setItem("total", JSON.stringify(0));
        localStorage.setItem("phoneNumber", "")
        localStorage.setItem("address", "")
        localStorage.setItem("remarks", "")
        localStorage.setItem("name", "")
        // localStorage.setItem("deliveryDay", "")
        this.toastService.show('Coșul de cumpărături a fost golit.')
      } else if (result == 'No') {
        dialogRef.close();
      }
    });

  }
}
