import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../data.service";
import {Subject} from "rxjs";
import {ToastService} from "../toast/toast.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  _unsubscribeAll;

  constructor(private _formBuilder: FormBuilder,
              private dataService: DataService,
              private toastService: ToastService,
              private router: Router) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.loginForm = this._formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login(form) {
    if (form.status == "VALID") {
      this.dataService.login(form.value).then(response => {
          this.router.navigateByUrl('/order/list')
        }
      )
    } else {
      this.markFormGroupTouched(form);
      this.toastService.show('Compleatează toate câmpurile.')
    }
  }

  private markFormGroupTouched(productForm: FormGroup) {
    (<any>Object).values(productForm.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
