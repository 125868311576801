<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav style="position: fixed"
               autoFocus="false"
               mode="over"
               [(opened)]="isMenuOpen"
               class="sidenav"
               (click)="toggle()">
    <a class="fas fa-times" style="text-align: right; padding: 0;font-size: 25px"></a>
    <a routerLink="/about" routerLinkActive="active">Despre noi</a>
    <a routerLink="/order" routerLinkActive="active">Comandă</a>
    <a (click)="scroll(target)">Contact</a>
  </mat-sidenav>
  <mat-sidenav-content>
    <div mat-button class="menu-button" (click)="toggle()" fxHide.gt-sm="true"
         *ngIf="currentRoute=='/order' || currentRoute=='/order/details' || currentRoute=='/order/finalize' || currentRoute=='/about'">
      <mat-icon style="position: fixed;z-index: 3000;top: 15px;right: 6px;cursor: pointer;">
        <i class="fas fa-ellipsis-v"></i>
      </mat-icon>
    </div>
    <main>

      <div class="header" fxLayout="row" fxLayoutAlign="start" fxHide fxShow.gt-sm
           *ngIf="currentRoute!='/login' && currentRoute!='/order/list' && currentRoute!='/products'">
        <img routerLink="/about"
             src="assets/images/logo.png"
             alt="">
        <div class="total" fxFlex fxLayoutAlign="end center">
          <a routerLink="/order" routerLinkActive="active">Comandă</a>
          <a routerLink="/about" routerLinkActive="active">Despre noi</a>
          <a (click)="scroll(target)">Contact</a>
          <div [className]="total>=60?'valid':'invalid'" routerLink="/order/details" fxLayout="row"
               fxLayoutAlign="center center">
            <img src="assets/images/basket.svg">
            <span>({{total ? total : 0}} Lei)</span>
          </div>
        </div>
      </div>

      <div class="headerXs" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs fxShow.sm
           *ngIf="currentRoute!='/login' && currentRoute!='/order/list' && currentRoute!='/products'">
        <img routerLink="/about"
             src="assets/images/logo.png"
             alt="">
        <div fxFlex [className]="total>=60?'valid':'invalid'" fxlayout="row" fxLayoutAlign="end center"
             routerLink="/order/details">
          <img src="assets/images/basket.svg" alt="">
          <span> ({{total ? total : 0}} Lei)</span>
        </div>
      </div>

      <router-outlet></router-outlet>

      <div class="footer-container"
           *ngIf="currentRoute!='/login' && currentRoute!='/order/list' && currentRoute!='/products'">
        <div class="footer" fxLayout="row" fxLayoutAlign="center"
             fxlayout.sm="column" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="15%" fxLayoutGap.xs="0"
             fxLayoutGap.sm="0"
             fxLayoutAlign.xs="center center">
          <div class="aboutUs" fxFlex="42" fxLayout="column" fxFlex.xs="30" fxFlex.sm="35">
            <div class="category-header" fxLayout="row" fxFlex="18" fxLayoutAlign="start end" fxFlex.xs="40"
                 fxFlex.sm="50">
              <img src="/assets/images/logo-alb.png" alt="">

            </div>
            <hr width="100%" style="background: #9a9a9a;">
            <div fxFlex>
              <p style="line-height: 25px;margin:28px 0 0 0;font-size: 15px;color: white;font-weight: 500; ">În
                produsele MereRo
                vei găsi un mic răsfăț, un strop din natură, o doză de sănătate și fericire. Fericirea se regăsește în
                sănătatea ta și a celor dragi ție!
              </p>
            </div>
            <img src="assets/images/mere-footer.png" alt="" fxHide.sm="true" fxHide.xs="true"
                 fxLayoutAlign="center center"
                 style="width:42%; height: 200px; width: 240px; margin: 0 0 10px 0;"></div>

          <!--    contact section-->

          <div class="contact" fxFlex="50" fxLayout="column" fxFlex.xs="60" fxFlex.sm="60">
            <div class="category-header" fxLayout="column" fxFlex="20" fxFlex.xs="25" fxFlex.sm="23"
                 fxLayoutAlign="end start"
                 fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
              <p style="font-size: 25px">Contact</p>
            </div>
            <div class="facebook" fxFlex="20" fxFlex.xs="25" fxFlex.sm="23" fxLayout="row" fxLayoutAlign="end start"
                 fxLayoutGap="30px"
                 fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
              <a href="https://m.me/516698118525202"><img class="icon_social_img"
                                                          src="assets/images/messenger-white.png" style="height: 38px"></a>
              <a class="fab fa-facebook" href="https://www.facebook.com/mererovoinesti"></a>
              <a href="https://wa.me/+40785212556"><img class="icon_social_img" src="assets/images/whatsapp-white.png"
                                                        style="height: 40px">
              </a>
            </div>

            <div class="address" fxLayout="row" fxFlex="15">
              <a class="fas fa-map-marker-alt"></a>
              <span style="font-size: 14px;max-width: 200px">Str. Principală, Nr.138, Voinești, Dâmbovița</span>
            </div>
            <div class="phone" fxFlex="15" fxLayout="row">
              <a class="fas fa-phone-alt" href="tel:0785212556"></a>
              <a href="tel:0785212556">0785212556</a>
            </div>

            <div class="mail" fxFlex="15" fxLayout="row">
              <a class="fas fa-envelope"></a>
              <span>suc.merero@gmail.com</span>
            </div>
          </div>
        </div>

      </div>
      <a class="facebook-large" href="https://m.me/516698118525202" target="_blank" fxLayout="row"
         fxLayoutAlign="center center" fxHide.sm="true" fxHide.xs="true"
         *ngIf="currentRoute!='/login' && currentRoute!='/order/list' && currentRoute!='/products'">
        <i fxFlex=""><img class="icon_social_img" src="assets/images/messenger-white.png"
                          style="height: 24px;margin-top: 4px"></i>
        <p fxFlex="70" style="font-size: 14px!important; font-weight: 500">Contactează-ne!</p>
      </a>
      <a class="facebook-xs" href="https://m.me/516698118525202" target="_blank" fxHide.gt-sm="true"
         *ngIf="currentRoute!='/login' && currentRoute!='/order/list' && currentRoute!='/products'">
        <i fxFlex="">
          <svg height="35px" viewBox="-2 0 511 511.99922" width="35px" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="cornflowerblue"
              d="m95.863281 512c-7.460937 0-14.890625-2.054688-21.507812-6.148438-12.160157-7.511718-19.414063-20.53125-19.414063-34.828124v-60c-42.816406-54.246094-61.320312-121.988282-52.144531-191.503907 14.710937-111.53125 106.378906-202.945312 217.949219-217.347656 78.585937-10.152344 155.726562 15.605469 211.726562 70.628906 56.003906 55.023438 83.101563 131.644531 74.359375 210.226563-12.667969 113.804687-104.566406 207.429687-218.5 222.628906-45.421875 6.023438-91.359375-.265625-133.503906-18.316406l-40.628906 20.300781c-5.820313 2.90625-12.09375 4.359375-18.335938 4.359375zm129.523438-473.832031c-95.539063 12.332031-174.027344 90.597656-186.632813 186.097656-8.121094 61.597656 9.019532 121.527344 48.277344 168.722656 2.707031 3.257813 4.1875 7.367188 4.1875 11.601563v66.433594c0 2.140624 1.207031 3.328124 2.207031 3.96875 1.027344.617187 2.648438 1.148437 4.554688.191406l48.324219-24.144532c4.945312-2.480468 10.789062-2.566406 15.820312-.195312 38.019531 17.839844 79.984375 24.359375 121.402344 18.851562 97.648437-13.023437 176.390625-93.21875 187.25-190.671874 7.503906-67.441407-15.730469-133.183594-63.738282-180.339844-48-47.175782-114.207031-69.25-181.652343-60.515625zm-2.320313-18h.175782zm0 0"/>
            <path
              fill="cornflowerblue"
              d="m128.398438 311.34375 83.273437-118.519531c3.46875-6.070313 11.46875-7.722657 17.058594-3.53125l55.785156 41.835937c3.78125 2.835938 8.898437 3.097656 12.949219.667969l67.929687-48.015625c9.96875-5.980469 21.566407 4.761719 16.371094 15.160156l-67.25 116.355469c-3.027344 6.054687-10.566406 8.25-16.371094 4.765625l-74.472656-44.683594c-3.40625-2.042968-7.621094-2.207031-11.175781-.429687l-68.816406 52.546875c-10.277344 5.144531-20.984376-6.171875-15.28125-16.152344zm0 0"/>
          </svg>
        </i>
      </a>
      <a class="whatsapp" href="https://wa.me/+40785212556" fxHide.gt-sm="true"
         *ngIf="currentRoute!='/login' && currentRoute!='/order/list' && currentRoute!='/products'">
        <i class="fab fa-whatsapp"></i>
      </a>
      <div #target></div>

    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

