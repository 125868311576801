import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Inject, Injectable, Injector} from '@angular/core';

import {ToastComponent} from './toast.component';
import {DOCUMENT} from "@angular/common";

@Injectable()
export class ToastService {

  private toastInstance: ToastComponent;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              @Inject(DOCUMENT) private _document: any,
              private injector: Injector) {
  }

  private appendComponentToBody() {
    return new Promise((resolve, reject) => {
      const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(ToastComponent)
        .create(this.injector);

      this.appRef.attachView(componentRef.hostView);

      const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

      this.toastInstance = componentRef.instance;
      this._document.body.appendChild(domElem);
      setTimeout(() => {
        resolve(componentRef.instance);
      })
    });
  }

  show(message: string) {
    if (this.toastInstance) {
      this.toastInstance.showToast(message);
    } else {
      this.appendComponentToBody().then((instance) => {
        this.toastInstance.showToast(message);
      });
    }
  }

}
