import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DataService} from '../data.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  productForm: FormGroup;
  productFormErrors: any;
  _unsubscribeAll;
  product;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _formBuilder: FormBuilder,
              private dataService: DataService,
              public dialogRef: MatDialogRef<AddProductComponent>,) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.product = this.data.product;
    this.productForm = this._formBuilder.group({
      id: [this.product ? this.product.id : ''],
      name: [this.product ? this.product.name : '', Validators.required],
      category: [this.product ? this.product.category : '', Validators.required],
      favourite: [this.product ? this.product.favourite : '', Validators.required],
      unit: [this.product ? this.product.unit : '', Validators.required],
      price: [this.product ? this.product.price : '', Validators.required],
      number: [this.product ? this.product.number : '', Validators.required],
      photo: [this.product ? this.product.photo : '', Validators.required],
      description: [this.product ? this.product.description : ''],
      contains: [this.product ? this.product.contains : ''],
    });
    this.productForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.onClientFormValueChanged();
      });
  }

  onClientFormValueChanged(): void {
    for (const field in this.productFormErrors) {
      if (!this.productFormErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.productFormErrors[field] = {};

      // Get the control
      const control = this.productForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.productFormErrors[field] = control.errors;
      }
    }

  }

  editProduct(form) {
    if (form.status == "VALID") {
      this.dataService.editProduct(form.value).then(product => {
        this.data.edit(product)
        this.dialogRef.close();
      })
    } else {
      this.markFormGroupTouched(form);
    }
  }

  addProduct(form) {
    if (form.status == "VALID") {
      this.dataService.addProduct(form.value).then(product => {
        this.data.add(product);
        this.dialogRef.close();
      }).catch(error =>
        console.log(error))
    } else {
      this.markFormGroupTouched(form);
    }
  }

  ////Mark all form fields as touched///
  private markFormGroupTouched(productForm: FormGroup) {
    (<any>Object).values(productForm.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}

