import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HOST} from '../config';
import {ToastService} from './toast/toast.service';
import {Router} from "@angular/router";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private liveOrderTotal = new Subject<string>()
  orderTotal$ = this.liveOrderTotal.asObservable()

  constructor(private http: HttpClient,
              private toastService: ToastService,
              private router: Router){
  }


  sendOrderTotal(total){
    this.liveOrderTotal.next(total);
  }

  // PRODUCT/////

  getAllProducts() {

    return new Promise((resolve, reject) => {
      this.http.get(HOST + 'getAllProducts').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        });
    });
  }

  deleteProduct(product) {
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'deleteProduct', product).subscribe((response: any) => {
          this.toastService.show('Produsul a fost eliminat din listă.');
          resolve(response);
          },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
  }

  addProduct(product) {
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'insertProduct', product).subscribe((response: any) => {
          this.toastService.show('Produsul a fost adăugat.');
          resolve(response);
        },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
  }

  editProduct(product) {
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'editProduct', product).subscribe((response: any) => {
          this.toastService.show('Produsul a fost modificat.');
          resolve(response);
        },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
  }

  addMotd(motd) {
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'editMotd', motd).subscribe((response: any) => {
          this.toastService.show('Mesajul a fost adaugat.');
          resolve(response);
        },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
  }
  getMotd() {
    return new Promise((resolve, reject) => {
      this.http.get(HOST + 'getMotd').subscribe((response: any) => {
          resolve(response);
        },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
  }




  // Order//////

  getAllOrders() {
    return new Promise((resolve, reject) => {
      this.http.get(HOST + 'getAllOrders').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        });

    });
  }

  addOrder(order) {
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'insertNewOrder', order).subscribe((response: any) => {
          resolve(response);
        },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
  }

  deleteOrder(order) {
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'deleteOrder', order).subscribe((response: any) => {
          this.toastService.show('Comanda a fost eliminată in listă.');
          resolve(response);
        },
        error => {
          reject(error);
          this.toastService.show(error);
        });
    });
}
  login(form){
    return new Promise((resolve, reject) => {
      this.http.post(HOST + 'login', form).subscribe((response: any) => {
          resolve(response);
          localStorage.setItem("loggedUser", JSON.stringify(form));
        },
        error => {
          reject(error);
          this.toastService.show('Credențiale Invalide')
        });
    });
  }
  logout(){
    localStorage.setItem("loggedUser", JSON.stringify(''));
      this.router.navigateByUrl('/login')
  }

  gotoTop() {
    window.scroll({
      top:0,
      left: 0,
      behavior: "auto"
    });
  }
}
