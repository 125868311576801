<div class="main-container" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="5%">
  <div class="container" fxLayout="column" fxFlex="40" fxLayoutAlign="center center">
    <div class="order-container" fxLayout="column">
      <div class="header" fxFlex="8" fxLayout="row" fxLayoutGap="2%">
        <h4 fxFlex="33" fxFlex.xs="55">Nume produs</h4>
        <h4 fxFlex="28" fxHide.xs="true">Preț unitate</h4>
        <h4 fxFlex="23" fxFlex.xs="25">Număr produse</h4>
        <h4 fxFlex="10" fxFlex.xs="20">Preț</h4>
      </div>
      <hr>

      <div class="row-container" fxFlex="72" fxLayout="column">
        <div class="row" fxFlex="12" fxFlex.xs="16" fxFlex.sm="16" *ngFor="let product of products | sort:'number'"
             fxLayoutGap="2%">
          <p fxFlex="45" fxFlex.xs="55">{{product.productName}}</p>
          <p fxFlex="25" fxHide.xs="true">{{product.productPrice}} Lei/{{product.productUnit}}</p>
          <p fxFlex="20" fxFlex.xs="25">{{product.quantity}}</p>
          <p fxFlex="10" fxFlex.xs="20">{{product.productPrice * product.quantity}} Lei</p>
        </div>
      </div>
      <hr>
      <div class="total" fxFlex="8" fxLayout="row" style="align-items: center">
        <p fxFlex="84" style="font-weight: 600; font-size: 22px; margin: 4px 0 0 3%;">Total</p>
        <p style=" font-weight: 600;margin: 4px 3% 0 0; text-align: end" fxFlex="10" fxFlex.sm="15"
           fxFlex.xs="30">{{total ? total : 0}} Lei</p>
      </div>
      <div class="buttonsContainer" fxFlex="8" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap.xs="16%"
           fxLayoutGap.sm="26%" fxLayoutGap="46%">
        <div class="button" fxLayout="column" fxFlexAlign="center  center" fxFlex.xs="40" fxFlex.sm="35" fxFlex="25">
          <button (click)="emptyShoppingCart()"
                  fxLayoutAlign="center center"> Golește coșul <i style="padding-left: 7px"
                                                                  class="fas fa-chevron-right"></i>
          </button>
        </div>
        <div class="button" fxLayout="column" fxFlexAlign="center  center" fxFlex.xs="40" fxFlex.sm="35" fxFlex="25">
          <button (click)="back()"
                  fxLayoutAlign="center center">Continuă cumpăraturile <i style="padding-left: 7px"
                                                                          class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="second-container" fxFlex="40" fxLayout="column" name="orderForm" [formGroup]="orderForm" novalidate>

    <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Nume <span style="color:#ff0000">*</span></p>
        <input placeholder="Ionel Popescu" formControlName="name">
      </div>
    </div>

    <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Adresă de livrare <span style="color:red">*</span></p>
        <input placeholder="Sector, Strada, Bloc... " formControlName="address">
      </div>
    </div>
    <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Oraș<span style="color:red">*</span></p>
        <input placeholder="Bucuresti.." formControlName="city">
      </div>
    </div>

    <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Număr de telefon <span style="color:#ff0000">*</span></p>
        <input type="tel" placeholder="07xx xxx xxx" formControlName="phoneNumber">
      </div>
    </div>
    <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Număr de telefon secundar</p>
        <input type="tel" placeholder="07xx xxx xxx" formControlName="phoneNumber2">
      </div>
    </div>

    <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Mențiuni</p>
        <input placeholder="Blocul este vis a vis de..." formControlName="remarks">
      </div>
    </div>
        <div class="card-container-small" fxLayout="column">
      <div class="card">
        <p>Metodă de plată<span style="color:#ff0000">*</span></p>


        <mat-radio-group fxLayout="column" aria-label="Select an option" style="padding: 5px 0 5px 3%" formControlName="promoCode" fxLayoutGap="15px" >
          <mat-radio-button value="Cash">Cash</mat-radio-button>
          <mat-radio-button value="Card" >Card (doar contactless la livrare)</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="button" fxLayout="column" fxFlexAlign="center  center" fxFlex="10">
      <button (click)="finalizeOrder(orderForm)"
              fxLayoutAlign="center center">Finalizează Comanda <i style="padding-left: 10px"
                                                                   class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>


</div>
