import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {CheckComponent} from "../check/check.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  allOrders;
  orders;
  totals = [];
  i;
  initialOrders;
  products = [];

  constructor(private dataService: DataService,
              private dialog: MatDialog) {


  }

  ngOnInit(): void {
    this.dataService.getAllOrders().then(orders => {
      this.allOrders = orders;
      this.orders = this.allOrders.sort((a, b) => +new Date(b.date) - +new Date(a.date)).slice(0, 50);

    })
  }

  deleteOrder(order) {
    const dialogRef = this.dialog.open(CheckComponent, {
      data: {
        order: order
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.dataService.deleteOrder(order).then(o => {
          this.orders = this.orders.filter(deletedOrder => deletedOrder.id != order.id)
        })
      } else if (result == 'No') {
        dialogRef.close();
      }
    });
  }

  logout() {
    this.dataService.logout();
  }
}
