import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {OrderPageComponent} from './order-page/order-page.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {OrderListComponent} from './order-list/order-list.component';
import {OrderFinalizeComponent} from './order-finalize/order-finalize.component';
import {ProductsComponent} from './products/products.component';
import {LoginComponent} from "./login/login.component";
import {AuthGuardService} from "./auth.service";
import {AboutComponent} from "./about/about.component";

const routes: Routes = [

  {path: 'login', component: LoginComponent},
  {path: 'order', component: OrderPageComponent},
  {path: 'about', component: AboutComponent},
  {path: 'order/details', component: OrderDetailsComponent},
  {path: 'order/finalize', component: OrderFinalizeComponent},
  {path: 'order/list', component: OrderListComponent, canActivate: [AuthGuardService]},
  {path: 'products', component: ProductsComponent, canActivate: [AuthGuardService]},
  {path: '**', redirectTo: 'about',pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
