<div class="main-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="card-header" fxFlex="18" fxLayoutAlign="center center">
      <h1>Login</h1>
    </div>
    <form class="text-center" name="registerForm" [formGroup]="loginForm">

      <mat-form-field appearance="outline">
        <mat-label>Nume</mat-label>
        <input matInput type="text" formControlName="user">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Parolă</mat-label>
        <input matInput type="password" formControlName="password">
      </mat-form-field>
    </form>

    <button mat-raised-button aria-label="Save"
            (click)="login(loginForm)" class="submit"
            fxLayoutAlign="center center" fxFlex="10">
      <span>Login</span>
    </button>

  </div>
</div>
