import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<CheckComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
  }


  reject() {
    this.dialogRef.close('No')
  }

  accept() {
    this.dialogRef.close('Yes')
  }
}
