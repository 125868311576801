import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {CheckComponent} from '../check/check.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddProductComponent} from '../add-product/add-product.component';
import {AddMotdComponent} from "../add-motd/add-motd.component";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products;

  constructor(private dataService: DataService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.dataService.getAllProducts().then(products => {
      this.products = products;
    })
  }

  deleteProduct(product) {
    const dialogRef = this.dialog.open(CheckComponent, {
      data: {
        product: product
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.dataService.deleteProduct(product).then(
          this.products.splice(this.products.indexOf(product), 1)
        ).catch(error => console.log(error))
      } else if (result == 'No') {
        dialogRef.close();
      }
    });
  }

  addProduct(param) {
    const dialogRef = this.dialog.open(AddProductComponent, {
      data: {
        param: param,
        add: (product) => {
          this.products.push(product);
        }
      }
    });
  }

  editProduct(product, param) {
    const dialogRef = this.dialog.open(AddProductComponent, {
      data: {
        product: product,
        param: param,
        edit: (editedproduct) => {
          this.products = this.products.filter(product => product.id !== editedproduct.id)
          this.products.push(editedproduct);
        }
      }
    });
  }

  addMotd() {
    const dialogRef = this.dialog.open(AddMotdComponent);
  }


  logout() {
    this.dataService.logout();
  }

}
