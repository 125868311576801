import { Component, OnInit } from '@angular/core';
import {DataService} from "../data.service";

@Component({
  selector: 'app-order-finalize',
  templateUrl: './order-finalize.component.html',
  styleUrls: ['./order-finalize.component.scss']
})
export class OrderFinalizeComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.gotoTop();
  }

}
