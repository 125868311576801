import {Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DataService} from '../data.service';
import {log} from "util";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  products;
  previewsSelections;
  list = [];
  singleOrder;
  selectedCategory: string = 'Fructe';

  @Output() onSelectedValue = new EventEmitter<any>();
  @Input() product: any;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    for (let i = 0; i <= 50; i++) {
      this.list.push(i)
    }
  }
  selectValue(value, productId, productName, productPrice, productUnit, productNumber, productDescription, productContains) {
    this.singleOrder = {
      quantity: value,
      number: productNumber,
      productId: productId,
      productName: productName,
      productPrice: productPrice,
      productUnit: productUnit
    }
    this.onSelectedValue.emit(this.singleOrder)


  }

}
