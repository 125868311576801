import {Component, OnInit} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {DataService} from "./data.service";
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ilie';
  currentRoute;
  total;
  public isMenuOpen: boolean = false;

  constructor(public router: Router,
              private dataService: DataService) {

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'G-BKKWNQXKTD', {'page_path':e.urlAfterRedirects});
    });

  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe((navEnd: NavigationEnd) => {
        this.currentRoute = navEnd.urlAfterRedirects;
      });
    this.total = JSON.parse(localStorage.getItem("total"));
    this.dataService.orderTotal$.subscribe(
      total => {
        this.total = total;
      }
    )
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  toggle(){
   if(this.isMenuOpen==true){
     this.isMenuOpen = false
   }
   else this.isMenuOpen = true;
  }
}
