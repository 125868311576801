<div class="card-container" fxLayout="column">
  <div class="card">
    <img src="{{product.photo}}">
    <h4>{{product.name}}</h4>
    <div class="description">
      <div class="description-text">
        <p>{{product.description}}</p>
      </div>
      <div class="contains" fxLayout="row" *ngIf="product.contains">
        <p style="font-weight: 600;margin-right: 5px"> Conține: </p>
        <p>{{product.contains}} </p>
      </div>
    </div>
    <p style="min-width: 100%;text-align: center;padding-top: 25px">{{product.price}} Lei/{{product.unit}}</p>
    <mat-form-field>
      <mat-select [value]="product.quantity? product.quantity: ''"
                  (selectionChange)="selectValue( $event.value ,product.id, product.name, product.price, product.unit, product.number, product.description, product.contains)">
        <mat-option *ngFor="let number of list" [value]="number">{{number}} {{product.unit}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
